import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import '@/assets/style/index.scss';
export default defineComponent({
  name: 'App',
  setup() {
    return () => _createVNode(_resolveComponent("el-config-provider"), {
      "locale": zhCn
    }, {
      default: () => [_createVNode(_resolveComponent("router-view"), null, null)]
    });
  }
});