import dayjs from 'dayjs';
const dealParams = (obj, method = 'get') => {
  const _obj = obj;
  if (!_obj) return false;
  Object.keys(_obj).forEach(key => {
    if (_obj[key] === '' || _obj[key] === null || _obj[key] === undefined || Array.isArray(_obj[key]) && _obj[key]?.length === 0) {
      // removeEmptyField
      delete _obj[key];
    }
    // else if (
    //   method === 'get' &&
    //   Array.isArray(_obj[key]) &&
    //   _obj[key]?.length > 0
    // ) {
    //   // when request method is 'GET', format Array param
    //   _obj[key] = _obj[key].filter((x: any) => x).join(',')
    // }
  });

  return _obj;
};
const matchNum = (val, precision = 2) => {
  var reg = new RegExp(`\\d+(\\.\\d{0,` + precision + `})?`);
  const value = val.match(reg) ? val.match(reg)[0] : '';
  return value;
};
const formatDate = (type, f = 'YYYY-MM-DD') => {
  if (!type) return;
  const todayStart = dayjs().format(f);
  const todayEnd = dayjs().format(f);
  const obj = {
    today: [todayStart, todayEnd],
    yesterday: [dayjs().subtract(1, 'day').format(f), dayjs().subtract(1, 'day').format(f)],
    week: [dayjs().subtract(1, 'week').format(f), todayEnd],
    month: [dayjs().subtract(1, 'month').format(f), todayEnd],
    sixMonth: [todayStart, dayjs().add(6, 'month').format(f)],
    threeMonth: [todayStart, dayjs().add(3, 'month').format(f)],
    oneMonth: [todayStart, dayjs().add(1, 'month').format(f)]
  };
  return obj[type];
};
const getPropertyType = val => {
  const propertyTypeList = [{
    houseBizType: '办公',
    delegateType: '租赁',
    temp: 'officeRent'
  }, {
    houseBizType: '办公',
    delegateType: '出售',
    temp: 'officeSell'
  }, {
    houseBizType: '商业',
    delegateType: '租赁',
    temp: 'shopRent'
  }, {
    houseBizType: '商业',
    delegateType: '出售',
    temp: 'shopSell'
  }, {
    houseBizType: '厂房',
    delegateType: '租赁',
    temp: 'shopRent'
  }, {
    houseBizType: '厂房',
    delegateType: '出售',
    temp: 'shopSell'
  }];
  const cur = propertyTypeList.find(x => x.delegateType === val.delegateType && x.houseBizType === val.houseBizType);
  return cur.temp;
};
const clipboard = (text = '') => {
  return new Promise(resolve => {
    const $input = document.createElement('textarea');
    $input.value = text;
    document.body.appendChild($input);
    $input.select();
    document.execCommand('Copy');
    document.body.removeChild($input);
    if (text) {
      resolve(text);
    }
  });
};
const isEmpty = val => {
  return val === '' || val === null || val === undefined;
};
const hasPermission = authCode => {
  const user = JSON.parse(localStorage.getItem('rent-sell-easy') ?? '');
  const {
    permissionCodes
  } = user;
  return permissionCodes.includes(authCode);
};
export { formatDate, dealParams, matchNum, getPropertyType, clipboard, isEmpty, hasPermission };