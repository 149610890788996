import { createApp } from 'vue';
import App from './App';
import router from './router';
import installElementPlus from '@/plugins/element-ui/index';
import store from './store';
import vue3PreviewImage from 'vue3-preview-image';
import Directive from '@/utils/directive';
const app = createApp(App);
installElementPlus(app);
Directive(app);
app.use(vue3PreviewImage);
app.use(router).use(store).mount('#app');