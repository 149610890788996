import { hasPermission } from '@/utils/index';
export default (app => {
  app.directive('auth', {
    mounted(el, binding, vnode) {
      if (binding.value && !hasPermission(binding.value)) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      }
    }
  });
});