import "core-js/modules/es.array.push.js";
import { defineStore } from 'pinia';
const useTabsViewStore = defineStore('tabsView', {
  state: () => {
    return {
      visitedViews: [],
      currentView: ''
    };
  },
  persist: {
    enabled: true,
    strategies: [{
      key: 'rent-sell-easy-views',
      storage: sessionStorage
    }]
  },
  actions: {
    addViews(payload) {
      let obj = {
        title: payload.meta.title,
        path: payload.path,
        fullPath: payload.fullPath
      };
      const isExit = this.visitedViews.findIndex(item => {
        return item.path === payload.path;
      });
      if (isExit === -1) {
        this.visitedViews.push(obj);
      }
      const iscChilPage = this.visitedViews.findIndex(item => {
        return item.path === payload.path && item.fullPath !== payload.fullPath;
      });
      // 如果是内页,替换参数
      if (iscChilPage > -1) {
        this.visitedViews[iscChilPage] = obj;
      }
      this.currentView = obj.path;
    },
    removeViews(payload) {
      const index = this.visitedViews.findIndex(item => {
        return item.path === payload;
      });
      return new Promise(resolve => {
        this.visitedViews.splice(index, 1);
        resolve(true);
      });
    }
  }
});
export default useTabsViewStore;