import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import useTabsViewStore from '@/store/modules/tabsView';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
const lookupRoutes = (arr, path) => {
  for (let i = 0; i < arr.length; i += 1) {
    const x = arr[i];
    if (x.path === path) return x.path;
    if (x.children && x.children.length > 0) {
      let temp = lookupRoutes(x.children, path);
      if (temp) return temp;
    }
  }
};
const pagelist = ['/login', '/map'];
router.beforeEach(to => {
  NProgress.start();
  // 判断路由列表存在才追加,避免出现空tab
  if (pagelist.indexOf(to.path) === -1 && lookupRoutes(routes, to.path)) {
    useTabsViewStore().addViews(to);
  }
});
router.afterEach(() => {
  NProgress.done();
});
export default router;